import React, { useState, useEffect, useRef, useCallback } from 'react';

import { MicrophoneIcon, StopIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import axios from 'axios';

import LogoSVG from './photo/logoWhite.svg';
import LogoBlackSVG from './photo/logoBlack.svg';

import { v4 as uuidv4 } from 'uuid';

const OPENAI_API_KEY = process.env.OPENAI_API_KEY || 'sk-proj-SW7q8aNMvbtQAHd28HM5T3BlbkFJduB0gIFL7sDRByjCsnnT';

const ELEVEN_LABS_API_KEY = process.env.ELEVEN_LABS_API_KEY || '44b0a33fc613a0fad8edc710bc70d869';
const ELEVEN_LABS_VOICE_ID = process.env.ELEVEN_LABS_VOICE_ID || 'ThT5KcBeYPX3keUQqHPh';

import { ClientJS } from 'clientjs';

const DEFAULT_MESSAGES = [
  {
    role: 'system',
    content: 'How can i help you?',
  },
];

const OPENAI_TTS_VOICES = 'alloy';

function useLocalStorage(key, defaultValue) {
  const [ value, setValue ] = useState(() => {
    const item = localStorage.getItem(key);
    try {
      return item ? JSON.parse(item) : defaultValue;
    } catch (e) {
      console.error(e);
      return defaultValue;
    }
  });
  const update = useCallback(
    (newValue) => {
      if (typeof newValue === 'function') {
        setValue((oldValue) => {
          const nextValue = newValue(oldValue);
          localStorage.setItem(key, JSON.stringify(nextValue));
          return nextValue;
        });
      } else {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    },
    [ key ],
  );
  return [ value, update ];
}

function completeChat(history) {
  const apiKey = OPENAI_API_KEY || localStorage.getItem('OPENAI_API_KEY');

  return fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'authorization, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      Authorization: `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: history,
    }),
  })
    .then((r) => r.json())
    .then((r) => r.choices[0].message.content.trim())
    .catch((e) => {
      console.error(e);
      alert(
        'Request to OpenAI API Failed. Please update your API key in by clicking \'Settings\'.',
      );
      return null;
    });
}

async function completeChatStreaming(history, language, uuid, onMessage?: (delta: string, language: string) => void) {
  const apiKey = OPENAI_API_KEY || localStorage.getItem('OPENAI_API_KEY');
  let lastMessage;
  history.forEach((el, index) => {
    if (el.role === 'user' && history.length - 1 === index) {
      return lastMessage = el;
    }
    return;
  });

  await axios.post(process.env.CHAT_ASSISTANT_URL, {
    messages: history,
    language: language.substring(0, 2),
    uuidthread: uuid.toString(),
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'authorization, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
    .then(response => {
      onMessage(response.data.content, language);
    })
    .catch(error => {
      console.log(error);
    });

  //return fetchEventSource("https://api.openai.com/v1/chat/completions", {
  //  method: "POST",
  //  headers: {
  //    "Content-Type": "application/json",
  //    Authorization: `Bearer ${apiKey}`,
  //  },
  //  body: JSON.stringify({
  //    model: "gpt-3.5-turbo",
  //    stream: true,
  //    messages: history,
  //  }),
  //  onmessage(event) {
  //    const { data } = event;
  //    if (data === '[DONE]') {
  //      return;
  //    }
  //    try {
  //      const {
  //        choices: [
  //          {
  //            delta: { content },
  //          },
  //        ],
  //      } = JSON.parse(data);
  //      if (!content) {
  //        return;
  //      }
  //      if (onMessage) {
  //        onMessage(content);
  //      }
  //    } catch (e) {
  //      console.error(e);
  //      console.log(data);
  //      return;
  //    }
  //  },
  //});
}

function transcribeAudio(formData) {
  return fetch('https://api.openai.com/v1/audio/transcriptions', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
    },
    body: formData,
  })
    .then((r) => r.json())
    .then((data) => data.text.trim());
}

const GET_VOICES_DELAY_MS = 100;
const PAUSE_DELAY_MS = 1000;

function genAudio(text: string) {
  const apivoice = localStorage.getItem('OPENAI_TTS_VOICES') || OPENAI_TTS_VOICES;
  const body = JSON.stringify({
    voice: apivoice,
    input: text,
    model: 'tts-1',
    response_format: 'mp3',
  });

  return fetch('https://api.openai.com/v1/audio/speech', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${OPENAI_API_KEY}`,
    },
    body,
  })
    .then((r) => r.blob());

}

async function speak(text: string, language: string) {
  const synth = window.speechSynthesis;
  const PAUSE_DELAY_MS = 1000;
  const GET_VOICES_DELAY_MS = 100;

  var timeout;

  function timeoutFunction() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
    } else {
      synth.pause();
      synth.resume();
    }
    timeout = setTimeout(timeoutFunction, PAUSE_DELAY_MS);
  }

  return new Promise<void>((resolve) => {
    setTimeout(() => {
      const allVoices = synth.getVoices();
      synth.cancel();
      timeout = setTimeout(timeoutFunction, PAUSE_DELAY_MS);
      let voice;
      switch (language) {
        case 'en':
          voice = allVoices.find((v) => v.lang === 'en-US');
          break;
        case 'uk':
          voice = allVoices.find((v) => v.lang === 'uk-UA');
          break;
        case 'ru':
          voice = allVoices.find((v) => v.lang === 'ru-RU');
          break;
        default:
          voice = allVoices.find((v) => v.lang === 'en-US');
          break;
      }

      const utterance = new window.SpeechSynthesisUtterance(text);
      // Set English voice if available, otherwise the default voice
      utterance.voice = voice || synth.getVoices()[0];
      utterance.rate = 1.1;
      utterance.pitch = 0.9;
      utterance.onend = () => {
        clearTimeout(timeout);
        resolve();
      };
      synth.speak(utterance);
    }, GET_VOICES_DELAY_MS);
  });
}

function generateAudio(text) {
  return fetch(
    `https://api.elevenlabs.io/v1/text-to-speech/${ELEVEN_LABS_VOICE_ID}`,
    {
      method: 'POST',
      headers: {
        Accept: 'audio/mpeg',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'authorization, Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'xi-api-key': `${ELEVEN_LABS_API_KEY}`,
      },
      body: JSON.stringify({
        text: text,
        voice_settings: {
          stability: 0,
          similarity_boost: 1,
        },
      }),
    },
  ).then((r) => r.blob());
}

let audio = new Audio();

async function playAudio(blob: Blob) {
  return new Promise<void>((resolve) => {
    audio.src = URL.createObjectURL(blob);
    audio.onended = () => {
      resolve();
    };
    audio.play();
  });
}

function stopAudio() {
  audio.pause(); // Pause the audio
}

function AudioRecorder({
  onRecording,
}: {
  onRecording?: (recording: Blob) => void;
}) {
  const [ permission, setPermission ] = useState(false);
  const [ stream, setStream ] = useState<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const [ recordingStatus, setRecordingStatus ] = useState('inactive');
  const [ audioChunks, setAudioChunks ] = useState<Blob[]>([]);

  const getMicAndRecord = () => {
    if (!('MediaRecorder' in window)) {
      alert('The MediaRecorder API is not supported in your browser.');
      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((streamData) => {
        setPermission(true);
        setRecordingStatus('recording');
        setStream(streamData);
        const media = new MediaRecorder(streamData, {
          type: 'audio/mp3',
        } as MediaRecorderOptions);
        //set the MediaRecorder instance to the mediaRecorder ref
        mediaRecorder.current = media;
        //invokes the start method to start the recording process
        mediaRecorder.current.start();
        let localAudioChunks: Blob[] = [];
        mediaRecorder.current.ondataavailable = (event) => {
          if (typeof event.data === 'undefined') return;
          if (event.data.size === 0) return;
          localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder.current === null) return;
    setRecordingStatus('inactive');
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
      if (onRecording) onRecording(audioBlob);
      //creates a playable URL from the blob file.
      setAudioChunks([]);
      if (stream) {
        stream
          .getTracks() // get all tracks from the MediaStream
          .forEach((track) => track.stop()); // stop each of them
        setStream(null);
        setPermission(false);
        mediaRecorder.current = null;
      }
    };
  };

  return (
    <>
      {!permission || recordingStatus === 'inactive' ? (
        <button
          onClick={getMicAndRecord}
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center text-center"
          title="Click to start recording"
        >
          <MicrophoneIcon className="h-5 w-5" />
        </button>
      ) : null}
      {recordingStatus === 'recording' ? (
        <button
          onClick={stopRecording}
          type="button"
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center text-center"
          title="Click to stop recording"
        >
          <StopIcon className="h-5 w-5" />
        </button>
      ) : null}
    </>
  );
}

function SpeechTranscriber({
  onTranscribed,
}: {
  onTranscribed: (string) => void;
}) {
  function handleRecording(recording: Blob) {
    const audioFile = new File([ recording ], 'file.mp3', {
      type: 'audio/mp3',
    });
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('model', 'whisper-1');
    formData.append('response_format', 'verbose_json');
    transcribeAudio(formData).then((text) => {
      onTranscribed(text);
    });
  }

  return <AudioRecorder onRecording={handleRecording} />;
}

function ChatBox({ messages, loading = false }) {
  const listRef = useRef<HTMLUListElement | null>(null);
  useEffect(() => {
    if (listRef.current === null) {
      return;
    }
    listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [ messages, loading ]);
  return (
    <ul
      className="p-2 bg-gray-200 rounded-lg overflow-y-scroll grow h-full gap-2 flex flex-col shadow-inner border-2 border-gray-200"
      ref={listRef}
    >
      {messages.map((message, index) => (
        <li key={index} onClick={async () => await playAudio(await genAudio(message.content))}>
          {message.role === 'user' ? (
            <div className="flex flex-row-reverse grow">
              <div className="bg-blue-500 rounded-lg p-2 shadow max-w-full break-words whitespace-pre-wrap">
                <p className="text-white">{message.content}</p>
              </div>
            </div>
          ) : message.role == 'assistant' ? (
            <div className="flex flex-row grow">
              <div className="bg-white rounded-lg p-2 shadow max-w-full break-words whitespace-pre-wrap">
                <p className="text-black">{message.content}</p>
              </div>
            </div>
          ) : (
            <div className="flex flex-row grow">
              <div className="bg-white rounded-lg p-2 shadow max-w-full w-full break-words whitespace-pre-wrap">
                <p className="text-gray-400 italic text-xs">{message.role}</p>
                <p className="text-gray-600 italic">{message.content}</p>
              </div>
            </div>
          )}
        </li>
      ))}
      {loading && (
        <Transition
          show={loading}
          appear={true}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-0"
          leaveFrom="opacity-0"
          leaveTo="opacity-0"
        >
          <li>
            <div className="flex flex-row grow">
              <div className="bg-white rounded-lg p-2 shadow max-w-full break-words whitespace-pre-wrap flex items-baseline gap-2">
                <div className="animate-spin rounded-full h-3 w-3 border-t-2 border-b-2 border-gray-900"></div>
                <p className="text-gray-600 italic">Generating response...</p>
              </div>
            </div>
          </li>
        </Transition>
      )}
    </ul>
  );
}

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

function SpeechTranscriberNative(
  { onTranscribed, language }: {
    onTranscribed?: (text: string) => void,
    language?: string
  },
) {
  const [ enabled, setEnabled ] = useState(false);
  useEffect(() => {
    if (SpeechRecognition) {
      if (!enabled) {
        return;
      }
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.lang = language;
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      recognition.onresult = (event) => {
        if (onTranscribed) {
          onTranscribed(
            event.results[0][event.results[0].length - 1].transcript,
          );
        }
      };
      recognition.onend = () => {
        recognition.start();
      };
      recognition.onerror = (error) => {
        console.error(error);
      };
      recognition.start();
      return () => {
        recognition.onend = null;
        recognition.onresult = null;
        recognition.onerror = null;
        recognition.stop();
      };
    }
  }, [ enabled, onTranscribed, language ]);

  return (
    <>
      {!enabled ? (
        <button
          onClick={() => setEnabled(true)}
          type="button"
          style={{ height: '40px' }}
          className="bg-blue-500 outline-none text-white px-4 py-2 ml-2 focus:ring-2 focus:ring-red-blue-500 focus:ring-offset-2 font-bold p-4 rounded flex justify-center items-center text-center"
          title="Click to start recording"
        >
          <MicrophoneIcon className="h-7 w-7" />
        </button>
      ) : (
        <button
          onClick={() => setEnabled(false)}
          type="button"
          style={{ height: '40px' }}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 ml-2 focus:ring-red-500 focus:ring-offset-2 font-bold p-4 rounded flex"
          title="Click to stop recording"
        >
          <div className="flex w-full h-full justify-center items-center text-center relative">
            <StopIcon className="h-7 w-7 relative" />
            <StopIcon className="h-7 w-7 absolute animate-ping" />
          </div>
        </button>
      )}
    </>
  );
}

function Chat() {
  const client = new ClientJS();
  const [ uuid, setUuid ] = useState(client.getFingerprint());
  const [ messages, setMessages ] = useLocalStorage('messages', DEFAULT_MESSAGES);
  const [ audioPlaying, setAudioPlaying ] = useState(false);
  const [ audio, setAudioOn ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ inputText, setInputText ] = useState('');
  const [ language, setLanguage ] = useState('en-US');

  async function sendMessage(message) {
    if (message === '') return;
    const newChat = [ ...messages, { role: 'user', content: message } ];
    setMessages(newChat);
    setLoading(true);
    let result = '';
    let lang = '';
    await completeChatStreaming(newChat, language, uuid, ((delta) => {
      setMessages((messages) => {
        lang += language;
        const lastMessage = messages[messages.length - 1];
        result += delta;
        if (lastMessage.role !== 'assistant') {
          setLoading(false);
          return [ ...messages, { role: 'assistant', content: delta } ];
        } else {
          return [
            ...messages.slice(0, -1),
            { ...lastMessage, content: lastMessage.content + delta },
          ];
        }
      });
    }));

    if (audio) {
      setAudioPlaying(true);
      //await speak(result, language);
      // uncomment to use the eleven labs api
      await playAudio(await genAudio(result));
      setAudioPlaying(false);
    }
  }

  async function reset() {
    const shouldReset = window.confirm('Are you sure you want to clear chat?');
    if (!shouldReset) return;
    //await axios.post(process.env.CHAT_ASSISTANT_URL, {
    //  user_message: 'clear',
    //  clear_chat_history: true,
    //}, {
    //  headers: {
    //    'Content-Type': 'application/json',
    //    'Access-Control-Allow-Origin': '*',
    //  }
    //})
    //  .then(response => {
    //    return response;
    //  })
    //  .catch(error => {
    //    console.log(error);
    //    return  error;
    //  });
    setMessages(DEFAULT_MESSAGES);
  };
  //function settings() {
  //  const apiKey = prompt(
  //    'Enter your OpenAI API key. Click \'Ok\' to clear key.',
  //  );
  //  if (apiKey === null) return;
  //  localStorage.setItem('OPENAI_API_KEY', apiKey);
  //  alert('API key saved!');
  //}

  return (
    <>
      <div className="flex flex-col gap-4 grow overflow-y-auto overflow-x-visible w-full p-1">
        <div className="flex flex-wrap justify-between items-center gap-y-4 gap-x-4">
          <h1 className="text-2xl text-blue-900 font-bold">
            <img src={LogoBlackSVG} alt="Logo" className="w-24 h-auto" />
          </h1>
          <div className="flex gap-4 items-center justify-center">
            <p>Audio {audio ? 'on' : 'off'}</p>
            <label className="relative inline-block w-12 h-6">
              <input type="checkbox" className="opacity-0 w-0 h-0 peer" />
              <span
                onClick={() => {
                  stopAudio();
                  return setAudioOn(!audio);
                }}
                className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 transition-all duration-400 rounded-full peer-checked:bg-blue-500 peer-focus:shadow-outline-blue peer-checked:before:translate-x-5 before:content-[''] before:absolute before:h-4 before:w-4 before:left-1 before:bottom-1 before:bg-white before:transition-all before:duration-400 before:rounded-full"
              ></span>
            </label>
            <button
              className="text-blue-400 hover:text-blue-500"
              onClick={reset}
            >
              Clear Chat
            </button>
            <select
              className="text-blue-400 hover:text-blue-500 border border-gray-200 rounded-lg px-2 py-1"
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="uk-UA">Ukrainian</option>
              <option value="ru-RU">Russian</option>
            </select>
          </div>
        </div>
        <ChatBox messages={messages} loading={loading} />
        <div className="flex items-center border border-gray-200 rounded-lg p-2">
          <input
            type="text"
            placeholder="Type your message here..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMessage(inputText);
                setInputText(''); // Clear the input after sending
              }
            }}
            className="flex-grow w-full md:w-300 outline-none px-2 py-1 p-2 bg-gray-200 rounded-lg border-2 border-gray-200"
          />
          <button
            onClick={() => {
              sendMessage(inputText);
              setInputText(''); // Clear the input after sending
            }}
            className="bg-blue-500 text-white px-4 py-2 ml-2 rounded-lg hover:bg-blue-600"
          >
            Send
          </button>
          <SpeechTranscriberNative
            onTranscribed={(text) => {
              if (audioPlaying) return;
              sendMessage(text);
            }}
            language={language}
          />
        </div>
      </div>
    </>
  );
}

const SignIn = ({ onSignIn }) => {
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState('');

  const handleSignIn = () => {
    const name = process.env.USER_NAME;
    if (username === process.env.REACT_APP_USER_NAME && password === process.env.REACT_APP_PASSWORD) {
      onSignIn();
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="sign-in-overlay">
      <div className="sign-in-modal">
        <h2>Sign In</h2>
        <div className="input-group">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="submit-button" onClick={handleSignIn}>Sign In</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export function App() {
  const [ signedIn, setSignedIn ] = useState(false);
  return (
    <>
      <header className="bg-gray-900 text-white p-4 flex justify-start items-center">
        <div>
          <img src={LogoSVG} alt="Logo" className="w-16 h-auto" />
        </div>
      </header>
      <div className="flex justify-center bg-gradient-to-br from-green-900 via-blue-900 to-teal-300 p-0 sm:p-8 h-full">
        <div
          className="max-w-prose w-full bg-white px-4 py-2 ml-2 rounded-lg rounded-none sm:rounded-2xl shadow-lg shadow-indigo-500/50 drop-shadow-xl flex flex-col gap-2 sm:gap-8"
          style={{ backgroundColor: '#D9D9D9', color: '#014029' }}
        >
          <Chat />
        </div>
      </div>
    </>
  );
}
